<template>
  <BaseAlert
    variant="success"
    :dismissible="dismissible"
    :duration="duration"
    @dismissed="$emit('dismissed')"
  >
    <template #icon>
      <SuccessIcon width="24" height="24" />
    </template>
    <slot />
  </BaseAlert>
</template>

<script>
import SuccessIcon from '~/assets/icons/success.svg'
import BaseAlert from '~/components/alerts/BaseAlert'

export default {
  components: {
    SuccessIcon,
    BaseAlert,
  },
  props: {
    dismissible: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: 0,
    },
  },
  emits: ['dismissed'],
}
</script>
